import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import { PageBody } from '../common/PageBody';
import { PageBodySection } from '../common/PageBodySection';
import { PageHeader } from '../common/PageHeader';
import MarcajesList from './marcajes/MarcajesList';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

function MarcajesOperarioView({ open, onClose, selectedJornada, tiempoAcumulado }) {
    const title = `${selectedJornada.operario}`;
    return (
        <>
            <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition} transitionDuration={0}>
                <PageHeader
                    title={title}
                    startButton={
                        <IconButton onClick={onClose}>
                            <ArrowBackIcon style={{ color: 'white' }} />
                        </IconButton>
                    }
                />
                <PageBody paddingTop={0}>
                    <PageBodySection>
                        <MarcajesList
                            selectedJornadaDate={selectedJornada.fecha}
                            tiempoAcumulado={tiempoAcumulado}
                            operarioId={selectedJornada.operario_id}
                        />
                    </PageBodySection>
                </PageBody>
            </Dialog>
        </>
    );
}

MarcajesOperarioView.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedJornada: PropTypes.object.isRequired,
    operarioId: PropTypes.string.isRequired,
    tiempoAcumulado: PropTypes.number.isRequired,
};

export default withButtonOpener(MarcajesOperarioView);
