import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { tareasProvider } from '../../../api';
import { createTiempo } from '../../../api/tareas-functions';
import useSplash from '../../../contexts/SplashState';
import { formatTiempo } from '../../../utils';
import { BaseEditTime } from '../EditTime';
import { SelectSubtareaView } from './SelectSubtareaView';

const useStyles = makeStyles(
    (theme) => ({
        emptyElement: {
            borderRadius: 4,
            border: '1px dashed #BABABA',
            minHeight: 38,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 13,
            color: '#818CAE',
            flex: 1,
            boxSizing: 'border-box',
        },
        hora: {
            background: 'white',
            borderRadius: 4,
            flex: 1,
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            fontSize: 12,
            color: '#818CAE',
            '& svg': {
                color: 'inherit',
                position: 'absolute',
                right: theme.spacing(1.5),
                fontSize: 20,
            },
        },
    }),
    { name: 'MarcajesList' },
);

export default function MarcajeTiempoItem(props) {
    const classes = useStyles();

    const tiempo = props.marcaje ? createTiempo(props.marcaje.minutos) : null;

    return (
        <BaseMarcajeTiempoItem
            {...props}
            element={
                <div className={tiempo ? classes.hora : classes.emptyElement}>
                    {tiempo ? (
                        <>
                            {formatTiempo(tiempo)}
                            {!props.disabled && <EditIcon />}
                        </>
                    ) : (
                        '+ Añadir'
                    )}
                </div>
            }
        />
    );
}

MarcajeTiempoItem.propTypes = {
    marcaje: PropTypes.object,
    disabled: PropTypes.bool,
};

export function useBaseMarcajeTiempoItem({
    tareaId,
    marcaje,
    selectedJornadaDate,
    onSave,
    disabled,
    subtareasList,
    operarioId,
}) {
    const { showCustomComponent } = useSplash();

    let url = `${tareaId}/jornadas/${selectedJornadaDate}/marcajes`;
    if (marcaje) url += `/${marcaje.id}`;

    const tiempo = marcaje ? createTiempo(marcaje.minutos) : null;

    return useCallback(
        function (ev, externalProps) {
            function showTimePicker(tiempo, extraBodyProps) {
                setTimeout(() => {
                    showCustomComponent(({ closeSplash }) => (
                        <BaseEditTime
                            title='Selecciona el tiempo'
                            selectedDate={new Date(selectedJornadaDate)}
                            onClose={closeSplash}
                            tiempo={tiempo}
                            onAccept={(tiempo) => {
                                const minutos = tiempo.horas * 60 + tiempo.minutos;
                                return tareasProvider
                                    .action(url, {
                                        body: JSON.stringify({
                                            minutos,
                                            operario_id: operarioId,
                                            ...extraBodyProps,
                                            ...externalProps,
                                        }),
                                        method: 'post',
                                    })
                                    .then((res) => {
                                        closeSplash();
                                        return onSave(res);
                                    });
                            }}
                        />
                    ));
                });
            }

            if (tiempo || subtareasList.length === 0) {
                showTimePicker(tiempo);
                return;
            }
            showCustomComponent(({ closeSplash }) => (
                <SelectSubtareaView
                    onChange={(selectedSubtarea) => {
                        showTimePicker(tiempo, { subtarea_id: selectedSubtarea.id });
                        closeSplash();
                    }}
                    subtareas={subtareasList}
                    onClose={closeSplash}
                    currentValue={null}
                />
            ));
            setTimeout(() => {
                document.querySelector('#global-filter-input').focus();
            }, 200);
        },
        [tareaId, marcaje, selectedJornadaDate, onSave, disabled, subtareasList, operarioId],
    );
}

export function BaseMarcajeTiempoItem({
    tareaId,
    marcaje,
    element,
    selectedJornadaDate,
    operarioId,
    onSave,
    subtareasList,
    disabled,
}) {
    const { showCustomComponent } = useSplash();

    let url = `${tareaId}/jornadas/${selectedJornadaDate}/marcajes`;
    if (marcaje) url += `/${marcaje.id}`;

    const tiempo = marcaje ? createTiempo(marcaje.minutos) : null;

    return React.cloneElement(element, {
        onClick: disabled
            ? undefined
            : () => {
                  function showTimePicker(tiempo, extraBodyProps) {
                      setTimeout(() => {
                          showCustomComponent(({ closeSplash }) => (
                              <BaseEditTime
                                  title='Selecciona el tiempo'
                                  selectedDate={new Date(selectedJornadaDate)}
                                  onClose={closeSplash}
                                  tiempo={tiempo}
                                  onAccept={(tiempo) => {
                                      const minutos = tiempo.horas * 60 + tiempo.minutos;
                                      return tareasProvider
                                          .action(url, {
                                              body: JSON.stringify({
                                                  minutos,
                                                  operario_id: operarioId,
                                                  ...extraBodyProps,
                                              }),
                                              method: 'post',
                                          })
                                          .then((res) => {
                                              closeSplash();
                                              return onSave(res);
                                          });
                                  }}
                              />
                          ));
                      });
                  }

                  if (tiempo || subtareasList.length === 0) {
                      showTimePicker(tiempo);
                      return;
                  }
                  showCustomComponent(({ closeSplash }) => (
                      <SelectSubtareaView
                          onChange={(selectedSubtarea) => {
                              showTimePicker(tiempo, { subtarea_id: selectedSubtarea.id });
                              closeSplash();
                          }}
                          subtareas={subtareasList}
                          onClose={closeSplash}
                          currentValue={null}
                      />
                  ));
                  setTimeout(() => {
                      document.querySelector('#global-filter-input').focus();
                  }, 200);
              },
    });
}

BaseMarcajeTiempoItem.propTypes = {
    marcaje: PropTypes.object,
    element: PropTypes.any,
    tareaId: PropTypes.string,
    selectedJornadaDate: PropTypes.string,
    operarioId: PropTypes.string,
    subtareasList: PropTypes.array,
    onSave: PropTypes.func,
    disabled: PropTypes.bool,
};
