import MuiButton from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import AssignmentIcon from '@material-ui/icons/Assignment';
import clsx from 'clsx';
import isToday from 'date-fns/isToday';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { tareasProvider } from '../../../api';
import { createTiempo } from '../../../api/tareas-functions';
import useAuthState from '../../../contexts/AuthState';
import useSplash from '../../../contexts/SplashState';
import { formatDate, formatTiempo } from '../../../utils';
import Button from '../../common/Button';
import { useTareaState } from '../TareaView';
import { DeleteDialog } from './DeleteDialog';
import MarcajeItem, { BaseMarcajeItem, useSubtareasList } from './MarcajeItem';
import MarcajeTiempoItem, { BaseMarcajeTiempoItem } from './MarcajeTiempoItem';
import { SelectSubtareaView } from './SelectSubtareaView';

const useStyles = makeStyles(
    (theme) => ({
        marcajesList: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(1)}px`,
        },
        emptyList: {
            borderRadius: 4,
            border: '1px dashed #BABABA',
            minHeight: 38,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 13,
            color: '#818CAE',
            flex: 1,
            boxSizing: 'border-box',
        },
        marcaje: {
            display: 'flex',
            flexDirection: 'column',
        },
        marcajeContent: {
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
        },
        marcajeSubtarea: {
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),
        },
        squareButton: {
            height: '100%',
            minWidth: 44,
            minHeight: 40,
            background: 'linear-gradient(180deg, #4D61FF 0%, #4CB1FF 100%)',
            '&.disabled': {
                opacity: 0.4,
            },
            padding: 0,
        },
        hoyTag: {
            background: 'linear-gradient(180deg, #4D62FF 0%, #4D96FF 100%), #EDF8F5',
            borderRadius: 20,
            color: 'white',
            width: 40,
            height: 20,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 11,
            marginTop: -2,
            marginBottom: -2,
        },
        resumen: {
            display: 'flex',
            alignItems: 'center',
            height: 38,
            color: '#818CAE',
            '& .MuiTypography-root': {
                flex: 1,
            },
            marginBottom: theme.spacing(1.5),
        },
        addButton: {
            marginLeft: 'auto',
            padding: theme.spacing(1, 1.5),
        },
    }),
    { name: 'MarcajesList' },
);

export default function MarcajesList({ selectedJornadaDate, calendarioButton, tiempoAcumulado, operarioId }) {
    const classes = useStyles();
    const {
        id,
        tarea: { jornadas, disabled: isFinalizada },
        refresh,
    } = useTareaState();
    const { showCustomComponent } = useSplash();

    const {
        userInfo: {
            preferencias: { usar_marcajes_jornadas: usarMarcajes },
        },
    } = useAuthState();

    const selectedJornada = useMemo(
        () =>
            jornadas.find(
                (j) =>
                    j.fecha === selectedJornadaDate &&
                    ((!operarioId && j.propia) || (operarioId && j.operario_id === operarioId)),
            ),
        [jornadas, selectedJornadaDate, operarioId],
    );

    const bloqueada = selectedJornada && (selectedJornada.facturada || selectedJornada.revisada);

    const { canAdd, marcajes, tiempoTotal } = useMemo(() => {
        if (!selectedJornada) {
            return { canAdd: true, marcajes: [], tiempoTotal: createTiempo(0) };
        }
        const canAdd =
            !bloqueada &&
            selectedJornada.marcajes.every(
                (marcaje) => !marcaje.hora_entrada || (marcaje.hora_entrada && marcaje.hora_salida),
            );
        const marcajes = selectedJornada.marcajes.map((marcaje) => ({
            ...marcaje,
            entradaMinTime: null,
            entradaMaxTime: null,
            salidaMinTime: null,
            salidaMaxTime: null,
        }));
        let minutos = 0;
        for (let i = 0; i < marcajes.length; i++) {
            const marcaje = marcajes[i];
            if (i > 0) {
                marcaje.entradaMinTime = new Date(marcajes[i - 1].hora_salida);
            }
            marcaje.entradaMaxTime = marcaje.hora_salida ? new Date(marcaje.hora_salida) : null;
            marcaje.salidaMinTime = new Date(marcaje.hora_entrada);

            if (i < marcajes.length - 1) {
                marcaje.salidaMaxTime = new Date(marcajes[i + 1].hora_entrada);
            }

            minutos += marcaje.minutos;
        }

        return { canAdd, marcajes, tiempoTotal: createTiempo(minutos) };
    }, [selectedJornada]);

    const selectedIsToday = !selectedJornadaDate || isToday(new Date(selectedJornadaDate));

    const subtareasList = useSubtareasList(selectedJornadaDate);

    return (
        <>
            <div className={classes.marcajesList}>
                {marcajes.length === 0 && (
                    <div className={classes.emptyList}>
                        No hay registro de tiempo para {selectedIsToday ? 'hoy' : 'esta fecha'}
                    </div>
                )}
                {marcajes.map((marcaje, idx) => (
                    <div key={idx} className={classes.marcaje}>
                        {marcaje.subtarea && (
                            <Typography variant='caption' className={classes.marcajeSubtarea}>
                                {marcaje.subtarea.titulo}
                            </Typography>
                        )}
                        <div className={classes.marcajeContent}>
                            {usarMarcajes ? (
                                <>
                                    <MarcajeItem
                                        tareaId={id}
                                        marcaje={marcaje}
                                        horaKey={'hora_entrada'}
                                        selectedJornadaDate={selectedJornadaDate}
                                        onSave={refresh}
                                        minTime={marcaje.entradaMinTime}
                                        maxTime={marcaje.entradaMaxTime}
                                        disabled={isFinalizada || bloqueada}
                                        subtareasList={subtareasList}
                                        operarioId={operarioId}
                                    />
                                    <MarcajeItem
                                        tareaId={id}
                                        marcaje={marcaje}
                                        horaKey={'hora_salida'}
                                        selectedJornadaDate={selectedJornadaDate}
                                        onSave={refresh}
                                        minTime={marcaje.salidaMinTime}
                                        maxTime={marcaje.salidaMaxTime}
                                        disabled={isFinalizada || bloqueada}
                                        subtareasList={subtareasList}
                                        operarioId={operarioId}
                                    />
                                </>
                            ) : (
                                <MarcajeTiempoItem
                                    tareaId={id}
                                    selectedJornadaDate={selectedJornadaDate}
                                    onSave={refresh}
                                    marcaje={marcaje}
                                    disabled={isFinalizada || bloqueada}
                                    subtareasList={subtareasList}
                                    operarioId={operarioId}
                                />
                            )}
                            {subtareasList.length > 0 && (
                                <MuiButton
                                    disabled={bloqueada}
                                    className={clsx(classes.squareButton, bloqueada ? 'disabled' : null)}
                                    onClick={() => {
                                        showCustomComponent(({ closeSplash }) => (
                                            <SelectSubtareaView
                                                onChange={(selectedSubtarea) => {
                                                    tareasProvider
                                                        .action(
                                                            `${id}/jornadas/${selectedJornadaDate}/marcajes/${marcaje.id}`,
                                                            {
                                                                method: 'post',
                                                                body: JSON.stringify({
                                                                    operario_id: operarioId,
                                                                    subtarea_id: selectedSubtarea.id,
                                                                }),
                                                            },
                                                        )
                                                        .then(refresh);

                                                    closeSplash();
                                                }}
                                                subtareas={subtareasList}
                                                onClose={closeSplash}
                                                currentValue={marcaje.subtarea}
                                            />
                                        ));
                                        setTimeout(() => {
                                            document.querySelector('#global-filter-input').focus();
                                        }, 200);
                                    }}
                                >
                                    <AssignmentIcon style={{ color: 'white', fontSize: 20 }} />
                                </MuiButton>
                            )}
                            <DeleteDialog
                                onDelete={() => {
                                    tareasProvider
                                        .action(`${id}/jornadas/${selectedJornadaDate}/marcajes/${marcaje.id}`, {
                                            method: 'delete',
                                            body: JSON.stringify({ operario_id: operarioId }),
                                        })
                                        .then(refresh);
                                }}
                                disabled={
                                    bloqueada || isFinalizada || (subtareasList.length === 0 && marcajes.length === 1)
                                }
                            />
                        </div>
                    </div>
                ))}
                {usarMarcajes && (
                    <BaseMarcajeItem
                        tareaId={id}
                        marcaje={null}
                        horaKey={'hora_entrada'}
                        selectedJornadaDate={selectedJornadaDate}
                        onSave={refresh}
                        minTime={marcajes.length > 0 ? new Date(marcajes[marcajes.length - 1].hora_salida) : null}
                        element={
                            <Button
                                disabled={!canAdd || isFinalizada}
                                startIcon={<AddIcon />}
                                color='primary'
                                className={classes.addButton}
                            >
                                Añadir tiempo
                            </Button>
                        }
                        subtareasList={subtareasList}
                        operarioId={operarioId}
                    />
                )}
                {!usarMarcajes && (marcajes.length === 0 || subtareasList.length > 0) && (
                    <BaseMarcajeTiempoItem
                        tareaId={id}
                        selectedJornadaDate={selectedJornadaDate}
                        onSave={refresh}
                        disabled={isFinalizada || bloqueada}
                        element={
                            <Button
                                disabled={!canAdd || isFinalizada}
                                startIcon={<AddIcon />}
                                color='primary'
                                className={classes.addButton}
                            >
                                Añadir tiempo
                            </Button>
                        }
                        subtareasList={subtareasList}
                        operarioId={operarioId}
                    />
                )}
                <div className={classes.resumen}>
                    <Typography>
                        <strong>{selectedIsToday ? 'Hoy' : formatDate(selectedJornadaDate)}:</strong>{' '}
                        {formatTiempo(tiempoTotal)}
                    </Typography>
                    <Typography>
                        <strong>Total:</strong> {formatTiempo(tiempoAcumulado)}
                    </Typography>
                    {calendarioButton}
                </div>
            </div>
        </>
    );
}

MarcajesList.propTypes = {
    calendarioButton: PropTypes.any,
    selectedJornadaDate: PropTypes.any,
    tiempoAcumulado: PropTypes.any,
    operarioId: PropTypes.any,
};
